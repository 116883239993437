import React, { useEffect } from 'react';
import './Links.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Code from './code_icon.png';
import Paper from './paper_icon.png';
import Circuit from './circuit_icon.png';

let iconSize = 100;

function Links({ width }) {
  return (
    <>
      <div className="links-container" id="links">
        <div className="links__title--wrapper" data-aos="fade-down">
          <h2 className="links__title">links</h2>
        </div>
        <div className="links__links--wrapper">

          <a href="https://github.com/swarmtronics/swarmodroid.pcb" className="links__links">
          <p className="links__links--text">
            <div className="image__wrapper">
              <img src={Circuit} alt="" width={iconSize} />
            </div>
            Printed circuit board design & supplementary documentation
          </p>
          </a>

          <a href="https://github.com/swarmtronics/AMPy" className="links__links">
            <p className="links__links--text">
              <div className="image__wrapper">
              <img src={Code} alt="" width={iconSize} />
              </div>
              Software for tracking & post-<br></br>-processing of experiments
            </p>
          </a>

          <a href="https://arxiv.org" className="links__links">
            <p className="links__links--text">
              <div className="image__wrapper">
                <img src={Paper} alt="" width={iconSize} />
              </div>
                Detailed description of the<br></br>platform (main article)
            </p>
          </a>

        </div>
      </div>
    </>
  );
}

export default Links;
