import React from 'react';
import news from './mediaData';

import './Slider.css';

const Slider = ({ id, resource, title, link, personIndex, index }) => {
  let position = 'nextSlide';
  if (personIndex === index) {
    position = 'activeSlide';
  }
  if (personIndex === index - 1 || (index === 0 && personIndex === news.length - 1)) {
    position = 'lastSlide';
  }

  return (
    <>
      <article
        className={`slider__container ${position}`}
        key={id}
        style={{ transform: `translate(-${personIndex * 100}%)` }}
      >
        <p className="slider__resource">{resource}</p>
        <p className="slider__title">{title}</p>
        <a className="slider__link" href={link}>
          Link to the article
        </a>
      </article>
    </>
  );
};

export default Slider;