import React from 'react';
import './AboutUs.css';

function AboutUs({ width }) {
  return (
    <>
      <div className="aboutUs-container" id="About-us">
        <div className="aboutUs__wrapper">
          <div className="aboutUs__title--wrapper">
            <div className="aboutUs__title">ABOUT US</div>
          </div>
          <div className="aboutUs__content">
            <div className="aboutUs__text">
              <p className="aboutUs__description">
                <span className="aboutUs__description--headline">SWARMTRONICS</span> team
                is dedicated to the development of robotic swarms composed of simple robots
                capable of self-organizing and performing complex tasks. To achieve our goal, we
                perform deep fundamental research of the underlying many-particle physics and develop
                robotic platforms distributed freely under GPL license.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
