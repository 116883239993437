import React, { useEffect } from 'react';
import './Swarmobot.css';

import Cap from './botImg/Cap.png';
import Body from './botImg/Body.png';
import Bristles from './botImg/Bristles.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { motion } from 'framer-motion';

function Swarmobot({ width }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  let swarmbotSize;
  if (width <= 960) {
    swarmbotSize = 180;
  } else swarmbotSize = 230;

  return (
    <>
      <div className="swarmobot-container" id="Swarmobot">
        <div className="swarmobot__description--wrapper">
          <div className="swarmobot__animation" data-aos="fade-right">
            <motion.div
              className="swarmobot__animation_cap"
              initial="hidden"
              whileInView="visible"
              animate={{
                y: [0, width <= 960 ? 60 : 90, 0],
              }}
              transition={{
                duration: 2.5,
                ease: 'easeInOut',
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 0.2,
              }}
            >
              <img src={Cap} alt="" width={swarmbotSize} />
            </motion.div>
            <motion.div
              className="swarmobot__animation_body"
              initial="hidden"
              whileInView="visible"
              animate={{}}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 0.2,
              }}
            >
              <img src={Body} alt="" width={swarmbotSize} />
            </motion.div>
            <motion.div
              className="swarmobot__animation_bristles"
              initial="hidden"
              whileInView="visible"
              animate={{
                y: [0, width <= 960 ? -60 : -90, 0],
              }}
              transition={{
                duration: 2.5,
                ease: 'easeInOut',
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 0.2,
              }}
            >
              <img src={Bristles} alt="" width={swarmbotSize} />
            </motion.div>
          </div>
          <div className="swarmobot__text">
            <div className="swarmobot__title--wrapper" data-aos="fade-down">
              <h2 className="swarmobot__title">Swarmobot</h2>
            </div>
            <p className="swarmobot__description" data-aos="fade-down">
              Swarmobot is an open platform for experiments in the emerging field of
              active matter. Remotely controlled and reconfigurable, Swarmobots are supplied with a
              recognition software for kinematics extraction making them ideal candidates for research projects.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Swarmobot;
